class nestedForm {
  constructor() {
    this.addFieldsLinks = document.querySelectorAll('.add_fields');
    this.iterateAddFieldsLinks();
    this.iterateRemoveFieldsLinks();
  }

  iterateAddFieldsLinks() {
    if (this.addFieldsLinks.length === 0) return;
    this.addFieldsLinks.forEach(link => {
      link.addEventListener('click', e => {
        this.handleAddField(link, e);
      })
    })
  }

  iterateRemoveFieldsLinks() {
    document.addEventListener('click', e => {
      if (e.target && e.target.classList.contains('remove_fields')) {
        this.handleRemoveField(e.target, e);
      }
    })
  }

  handleAddField(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    let time = new Date().getTime();
    let linkId = link.dataset.id;
    let regexp = linkId ? new RegExp(linkId, 'g') : null;
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
    newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null;
  }

  handleRemoveField(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    let fieldParent = link.closest('.nested-fields');
    let deleteField = fieldParent
      ? fieldParent.querySelector('input[type="hidden"]')
      : null;

    if (deleteField) {
      deleteField.value = 1;
      fieldParent.style.display = 'none';
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new nestedForm();
})
