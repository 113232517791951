import { createConsumer } from "@rails/actioncable"

let consumer;

function createChannelSubscription(...args) {
  if (!consumer) {
    consumer = createConsumer();
  }

  return consumer.subscriptions.create(...args);
}

export {
  consumer,
  createChannelSubscription,
}
