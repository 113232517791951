import 'core-js/stable'
import 'regenerator-runtime/runtime'
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// import * as ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()

import "bootstrap"
import "admin-lte"
import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application"
import 'admin/removable_items'
import 'admin/nested_form'
import 'admin/orders'
import 'admin/conversations'
import 'admin/grapes_listings_photo_upload'
