import Uppy, { debugLogger } from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Compressor from '@uppy/compressor'
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

const INPUT_ZONE_ID = 'js-photos-input-zone';

function sendDeleteRequestForFile(file) {
  const fileInput = document.querySelector(`[data-uppy-id="${file.id}"]`);
  fileInput.remove();
}

function onFileUploaded(file) {
  const signedId = file.response.signed_id;
  const uppyId = file.id;

  const grapesListingForm = document.querySelector('form.grapes_listing_form');

  const fileInput = document.createElement('input');
  fileInput.setAttribute('multiple', 'multiple');
  fileInput.setAttribute('type', 'hidden');
  fileInput.setAttribute('name', 'grapes_listing[photos][]');
  fileInput.setAttribute('id', 'grapes_listing_photos');
  fileInput.setAttribute('value', signedId);
  fileInput.dataset.uppyId = uppyId;

  grapesListingForm.insertAdjacentElement('beforeend', fileInput);
}

function setupUppy(element) {
  const directUploadUrl = element.dataset.directUploadUrl;

  const uppy = new Uppy({
    id: INPUT_ZONE_ID,
    logger: debugLogger,
    autoProceed: true,
    allowMultipleUploadBatches: true,
    restrictions: {
      allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png'],
    },
  })

  uppy.use(Dashboard, {
    target: '.js-dashboard-container',
    inline: true,
    height: 450,
    showProgressDetails: true,
    doneButtonHandler: null, // don't show Done button
    proudlyDisplayPoweredByUppy: false,
    hideCancelButton: true,
    showRemoveButtonAfterComplete: true,
  })

  uppy.use(ActiveStorageUpload, {
    directUploadUrl: directUploadUrl,
  })

  uppy.use(Compressor, {
    quality: 0.8,
  })

  uppy.on('complete', (result) => {
    result.successful.forEach(file => onFileUploaded(file))
  })

  uppy.on('file-removed', (file, reason) => {
    if (reason === 'removed-by-user') {
      sendDeleteRequestForFile(file)
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const grapesListingForm = document.querySelector('form.grapes_listing_form');

  if (grapesListingForm === null) return;

  document.querySelectorAll(`#${INPUT_ZONE_ID}`).forEach(element => setupUppy(element))
})
