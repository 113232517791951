document.addEventListener('DOMContentLoaded', function() {
  const btnShipmentDetailsExists = document.querySelector('.js-mark-as-shipped');

  if (btnShipmentDetailsExists != null) {
    addEventListenerOnModal('.js-mark-as-shipped');
    addEventListenerOnModal('.js-close-modal');
    onSubmitForm();
    addEventListenerOnShippedAt();
  }
});

function addEventListenerOnModal(className) {
  const formContainer = document.querySelector(className);

  formContainer.addEventListener('click', function() {
    const orderModal = document.getElementById('form-container');
    $('#form-container').modal('toggle');
  });
}

function onSubmitForm() {
  const submitForm = document.querySelector('.js-send-shipment-details');

  submitForm.addEventListener('click', function() {
    sendShipmentDetails();
  });
}

async function sendShipmentDetails() {
  const shipmentDetails = document.getElementById('shipment-details').value || null;
  const shippedAt = document.getElementById('shipped-at').value;
  const formAction = document.querySelector('#form-container form').action;
  const formToken = document.querySelector("meta[name='csrf-token']").content;

  let shipmentDetailsData = {
    authenticity_token: formToken,
    shipment_details: shipmentDetails,
    shipped_at: shippedAt
  };

  try {
    const response = await fetch(formAction, {
      method: 'PATCH',
      body: JSON.stringify(shipmentDetailsData),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });
    if (response.ok === true) {
      $('#form-container').modal('toggle');
      window.location.reload();
    } else
      return response.json();
    const data = undefined;
    showErrorMessages(data.error.join('. '));
  } catch (e) {
    showErrorMessages('Cannot connect to server');
  }
}

function addEventListenerOnShippedAt() {
  const shippedAt = document.querySelector('input#shipped-at');

  shippedAt.addEventListener('change', function(e) {
    updateShippedAtVal(e.target.value);
  });
}

function updateShippedAtVal(shipped_at) {
  document.querySelector('#form-container b.js-shipped-at-txt').innerHTML = shipped_at.replaceAll('-', '/');
}

function showErrorMessages(messages) {
  document.querySelector(".error-messages").innerText = messages;
}
