import { format } from 'date-fns'
import axios from 'axios'
import { createChannelSubscription } from '../channels/index'
import image from 'images/adminAvatar.png'

function scrollToContainerBottom(container) {
  const scrollHeight = container.scrollHeight;
  container.scrollTo(0, scrollHeight);
}

function initChatMessagesBlock(messagesContainer) {
  scrollToContainerBottom(messagesContainer);
}

function fromAdmin(message) {
  return message.sender_type === 'Admin';
}

function getCsrfToken() {
  const tokenMetaTag = document.querySelector("meta[name='csrf-token']");
  return (tokenMetaTag ? tokenMetaTag.getAttribute('content') : '');
}

function createMessageBuble(message, sender, conversationUserPhotoUrl) {
  let sentAt = new Date(message.created_at);
  let senderImageBlock
  if(fromAdmin(message)) {
    senderImageBlock = `<img src="${image}" class="direct-chat-img" />`
  } else {
    if (conversationUserPhotoUrl) {
      senderImageBlock = `<img src="${conversationUserPhotoUrl}" class="direct-chat-img" />`
    } else {
      senderImageBlock = `<div class="direct-chat-img direct-chat-img-placeholder">${sender.full_name.charAt(0)}</div>`
    }
  }

  return `
      <div class="direct-chat-msg ${fromAdmin(message) && 'right'}" data-message-id="${message.id}">
        <div class="direct-chat-infos clearfix">
          <span class="direct-chat-name ${fromAdmin(message) ? 'float-left' : 'float-right'}">
            ${fromAdmin(message) ? 'Admin' : sender.full_name}
          </span>
          <span class="direct-chat-timestamp ${fromAdmin(message) ? 'float-right' : 'float-left'}">
            ${format(sentAt, 'd MMM	yyyy K:m a')}
          </span>
        </div>
        <div></div>
        ${senderImageBlock}
        <div class="direct-chat-text">
          ${message.body}
        </div>
      </div>
      `
}

function receiveNewMessage(data, messagesContainer, conversationUserPhotoUrl, messagesSeenObserver) {
  const { message, sender } = data;

  if (fromAdmin(message)) {
    const messageInput = document.querySelector('#message_body')
    if(messageInput) {
      messageInput.value = ''
    }
  }
  const messageBubble = createMessageBuble(message, sender, conversationUserPhotoUrl);
  messagesContainer.insertAdjacentHTML("beforeend", messageBubble);
  if (!fromAdmin(message)) {
    const messageBubbleNode = document.querySelector(`[data-message-id="${message.id}"]`);
    if (messageBubbleNode) { messagesSeenObserver.observe(messageBubbleNode); }
  }
  scrollToContainerBottom(messagesContainer);
}

document.addEventListener('DOMContentLoaded', () => {
  const conversationContainer = document.querySelector('.js-conversation');
  const messagesContainer = document.querySelector('.js-conversation-messages');

  if (messagesContainer) {
    const conversationId = conversationContainer.getAttribute('data-conversation-id');
    const conversationUserPhotoUrl = conversationContainer.getAttribute('data-conversation-user-photo-url');

    initChatMessagesBlock(messagesContainer);

    const messagesSeenObserver = new IntersectionObserver(function (entries) {
      for (let i = 0; i < entries.length; i++) {
        if (entries[i]['isIntersecting'] === true) {
          let messageBubble = entries[i]['target'];
          axios.put(
            `/conversations/${conversationId}/messages/${messageBubble.getAttribute('data-message-id')}/mark_as_seen`,
            {},
            { headers: { 'X-CSRF-Token': getCsrfToken() } }
          ).then(response => {
            if (response.status === 200) {
              messagesSeenObserver.unobserve(messageBubble);
            }
          })
        }
      }
    }, { root: messagesContainer, threshold: [0.5] });


    document.querySelectorAll('.js-track-message-visibility').forEach((messageBubble) => {
      messagesSeenObserver.observe(messageBubble)
    })
    
    createChannelSubscription(
      { channel: "ConversationChannel", conversation_id: conversationId },
      {
        received(data) {
          receiveNewMessage(data, messagesContainer, conversationUserPhotoUrl, messagesSeenObserver)
        },
      }
    )
  }
})
